import { rectToClientRect as t, computePosition as e } from "@floating-ui/core";
export { arrow, autoPlacement, detectOverflow, flip, hide, inline, limitShift, offset, shift, size } from "@floating-ui/core";
function n(t) {
  var e;
  return (null == (e = t.ownerDocument) ? void 0 : e.defaultView) || window;
}
function o(t) {
  return n(t).getComputedStyle(t);
}
function i(t) {
  return t instanceof n(t).Node;
}
function r(t) {
  return i(t) ? (t.nodeName || "").toLowerCase() : "";
}
let l;
function c() {
  if (l) return l;
  const t = navigator.userAgentData;
  return t && Array.isArray(t.brands) ? (l = t.brands.map(t => t.brand + "/" + t.version).join(" "), l) : navigator.userAgent;
}
function s(t) {
  return t instanceof n(t).HTMLElement;
}
function f(t) {
  return t instanceof n(t).Element;
}
function u(t) {
  if ("undefined" == typeof ShadowRoot) return !1;
  return t instanceof n(t).ShadowRoot || t instanceof ShadowRoot;
}
function a(t) {
  const {
    overflow: e,
    overflowX: n,
    overflowY: i,
    display: r
  } = o(t);
  return /auto|scroll|overlay|hidden|clip/.test(e + i + n) && !["inline", "contents"].includes(r);
}
function d(t) {
  return ["table", "td", "th"].includes(r(t));
}
function h(t) {
  const e = /firefox/i.test(c()),
    n = o(t),
    i = n.backdropFilter || n.WebkitBackdropFilter;
  return "none" !== n.transform || "none" !== n.perspective || !!i && "none" !== i || e && "filter" === n.willChange || e && !!n.filter && "none" !== n.filter || ["transform", "perspective"].some(t => n.willChange.includes(t)) || ["paint", "layout", "strict", "content"].some(t => {
    const e = n.contain;
    return null != e && e.includes(t);
  });
}
function p() {
  return /^((?!chrome|android).)*safari/i.test(c());
}
function g(t) {
  return ["html", "body", "#document"].includes(r(t));
}
const m = Math.min,
  y = Math.max,
  x = Math.round;
function w(t) {
  const e = o(t);
  let n = parseFloat(e.width),
    i = parseFloat(e.height);
  const r = s(t),
    l = r ? t.offsetWidth : n,
    c = r ? t.offsetHeight : i,
    f = x(n) !== l || x(i) !== c;
  return f && (n = l, i = c), {
    width: n,
    height: i,
    fallback: f
  };
}
function v(t) {
  return f(t) ? t : t.contextElement;
}
const b = {
  x: 1,
  y: 1
};
function L(t) {
  const e = v(t);
  if (!s(e)) return b;
  const n = e.getBoundingClientRect(),
    {
      width: o,
      height: i,
      fallback: r
    } = w(e);
  let l = (r ? x(n.width) : n.width) / o,
    c = (r ? x(n.height) : n.height) / i;
  return l && Number.isFinite(l) || (l = 1), c && Number.isFinite(c) || (c = 1), {
    x: l,
    y: c
  };
}
function E(e, o, i, r) {
  var l, c;
  void 0 === o && (o = !1), void 0 === i && (i = !1);
  const s = e.getBoundingClientRect(),
    u = v(e);
  let a = b;
  o && (r ? f(r) && (a = L(r)) : a = L(e));
  const d = u ? n(u) : window,
    h = p() && i;
  let g = (s.left + (h && (null == (l = d.visualViewport) ? void 0 : l.offsetLeft) || 0)) / a.x,
    m = (s.top + (h && (null == (c = d.visualViewport) ? void 0 : c.offsetTop) || 0)) / a.y,
    y = s.width / a.x,
    x = s.height / a.y;
  if (u) {
    const t = n(u),
      e = r && f(r) ? n(r) : r;
    let o = t.frameElement;
    for (; o && r && e !== t;) {
      const t = L(o),
        e = o.getBoundingClientRect(),
        i = getComputedStyle(o);
      e.x += (o.clientLeft + parseFloat(i.paddingLeft)) * t.x, e.y += (o.clientTop + parseFloat(i.paddingTop)) * t.y, g *= t.x, m *= t.y, y *= t.x, x *= t.y, g += e.x, m += e.y, o = n(o).frameElement;
    }
  }
  return t({
    width: y,
    height: x,
    x: g,
    y: m
  });
}
function T(t) {
  return ((i(t) ? t.ownerDocument : t.document) || window.document).documentElement;
}
function R(t) {
  return f(t) ? {
    scrollLeft: t.scrollLeft,
    scrollTop: t.scrollTop
  } : {
    scrollLeft: t.pageXOffset,
    scrollTop: t.pageYOffset
  };
}
function C(t) {
  return E(T(t)).left + R(t).scrollLeft;
}
function F(t) {
  if ("html" === r(t)) return t;
  const e = t.assignedSlot || t.parentNode || u(t) && t.host || T(t);
  return u(e) ? e.host : e;
}
function W(t) {
  const e = F(t);
  return g(e) ? e.ownerDocument.body : s(e) && a(e) ? e : W(e);
}
function D(t, e) {
  var o;
  void 0 === e && (e = []);
  const i = W(t),
    r = i === (null == (o = t.ownerDocument) ? void 0 : o.body),
    l = n(i);
  return r ? e.concat(l, l.visualViewport || [], a(i) ? i : []) : e.concat(i, D(i));
}
function S(e, i, r) {
  let l;
  if ("viewport" === i) l = function (t, e) {
    const o = n(t),
      i = T(t),
      r = o.visualViewport;
    let l = i.clientWidth,
      c = i.clientHeight,
      s = 0,
      f = 0;
    if (r) {
      l = r.width, c = r.height;
      const t = p();
      (!t || t && "fixed" === e) && (s = r.offsetLeft, f = r.offsetTop);
    }
    return {
      width: l,
      height: c,
      x: s,
      y: f
    };
  }(e, r);else if ("document" === i) l = function (t) {
    const e = T(t),
      n = R(t),
      i = t.ownerDocument.body,
      r = y(e.scrollWidth, e.clientWidth, i.scrollWidth, i.clientWidth),
      l = y(e.scrollHeight, e.clientHeight, i.scrollHeight, i.clientHeight);
    let c = -n.scrollLeft + C(t);
    const s = -n.scrollTop;
    return "rtl" === o(i).direction && (c += y(e.clientWidth, i.clientWidth) - r), {
      width: r,
      height: l,
      x: c,
      y: s
    };
  }(T(e));else if (f(i)) l = function (t, e) {
    const n = E(t, !0, "fixed" === e),
      o = n.top + t.clientTop,
      i = n.left + t.clientLeft,
      r = s(t) ? L(t) : {
        x: 1,
        y: 1
      };
    return {
      width: t.clientWidth * r.x,
      height: t.clientHeight * r.y,
      x: i * r.x,
      y: o * r.y
    };
  }(i, r);else {
    const t = {
      ...i
    };
    if (p()) {
      var c, u;
      const o = n(e);
      t.x -= (null == (c = o.visualViewport) ? void 0 : c.offsetLeft) || 0, t.y -= (null == (u = o.visualViewport) ? void 0 : u.offsetTop) || 0;
    }
    l = t;
  }
  return t(l);
}
function A(t, e) {
  return s(t) && "fixed" !== o(t).position ? e ? e(t) : t.offsetParent : null;
}
function H(t, e) {
  const i = n(t);
  if (!s(t)) return i;
  let l = A(t, e);
  for (; l && d(l) && "static" === o(l).position;) l = A(l, e);
  return l && ("html" === r(l) || "body" === r(l) && "static" === o(l).position && !h(l)) ? i : l || function (t) {
    let e = F(t);
    for (; s(e) && !g(e);) {
      if (h(e)) return e;
      e = F(e);
    }
    return null;
  }(t) || i;
}
function V(t, e, n) {
  const o = s(e),
    i = T(e),
    l = E(t, !0, "fixed" === n, e);
  let c = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const f = {
    x: 0,
    y: 0
  };
  if (o || !o && "fixed" !== n) if (("body" !== r(e) || a(i)) && (c = R(e)), s(e)) {
    const t = E(e, !0);
    f.x = t.x + e.clientLeft, f.y = t.y + e.clientTop;
  } else i && (f.x = C(i));
  return {
    x: l.left + c.scrollLeft - f.x,
    y: l.top + c.scrollTop - f.y,
    width: l.width,
    height: l.height
  };
}
const O = {
  getClippingRect: function (t) {
    let {
      element: e,
      boundary: n,
      rootBoundary: i,
      strategy: l
    } = t;
    const c = "clippingAncestors" === n ? function (t, e) {
        const n = e.get(t);
        if (n) return n;
        let i = D(t).filter(t => f(t) && "body" !== r(t)),
          l = null;
        const c = "fixed" === o(t).position;
        let s = c ? F(t) : t;
        for (; f(s) && !g(s);) {
          const t = o(s),
            e = h(s);
          "fixed" === t.position ? l = null : (c ? e || l : e || "static" !== t.position || !l || !["absolute", "fixed"].includes(l.position)) ? l = t : i = i.filter(t => t !== s), s = F(s);
        }
        return e.set(t, i), i;
      }(e, this._c) : [].concat(n),
      s = [...c, i],
      u = s[0],
      a = s.reduce((t, n) => {
        const o = S(e, n, l);
        return t.top = y(o.top, t.top), t.right = m(o.right, t.right), t.bottom = m(o.bottom, t.bottom), t.left = y(o.left, t.left), t;
      }, S(e, u, l));
    return {
      width: a.right - a.left,
      height: a.bottom - a.top,
      x: a.left,
      y: a.top
    };
  },
  convertOffsetParentRelativeRectToViewportRelativeRect: function (t) {
    let {
      rect: e,
      offsetParent: n,
      strategy: o
    } = t;
    const i = s(n),
      l = T(n);
    if (n === l) return e;
    let c = {
        scrollLeft: 0,
        scrollTop: 0
      },
      f = {
        x: 1,
        y: 1
      };
    const u = {
      x: 0,
      y: 0
    };
    if ((i || !i && "fixed" !== o) && (("body" !== r(n) || a(l)) && (c = R(n)), s(n))) {
      const t = E(n);
      f = L(n), u.x = t.x + n.clientLeft, u.y = t.y + n.clientTop;
    }
    return {
      width: e.width * f.x,
      height: e.height * f.y,
      x: e.x * f.x - c.scrollLeft * f.x + u.x,
      y: e.y * f.y - c.scrollTop * f.y + u.y
    };
  },
  isElement: f,
  getDimensions: function (t) {
    return w(t);
  },
  getOffsetParent: H,
  getDocumentElement: T,
  getScale: L,
  async getElementRects(t) {
    let {
      reference: e,
      floating: n,
      strategy: o
    } = t;
    const i = this.getOffsetParent || H,
      r = this.getDimensions;
    return {
      reference: V(e, await i(n), o),
      floating: {
        x: 0,
        y: 0,
        ...(await r(n))
      }
    };
  },
  getClientRects: t => Array.from(t.getClientRects()),
  isRTL: t => "rtl" === o(t).direction
};
function P(t, e, n, o) {
  void 0 === o && (o = {});
  const {
      ancestorScroll: i = !0,
      ancestorResize: r = !0,
      elementResize: l = !0,
      animationFrame: c = !1
    } = o,
    s = i && !c,
    u = s || r ? [...(f(t) ? D(t) : t.contextElement ? D(t.contextElement) : []), ...D(e)] : [];
  u.forEach(t => {
    s && t.addEventListener("scroll", n, {
      passive: !0
    }), r && t.addEventListener("resize", n);
  });
  let a,
    d = null;
  l && (d = new ResizeObserver(() => {
    n();
  }), f(t) && !c && d.observe(t), f(t) || !t.contextElement || c || d.observe(t.contextElement), d.observe(e));
  let h = c ? E(t) : null;
  return c && function e() {
    const o = E(t);
    !h || o.x === h.x && o.y === h.y && o.width === h.width && o.height === h.height || n();
    h = o, a = requestAnimationFrame(e);
  }(), n(), () => {
    var t;
    u.forEach(t => {
      s && t.removeEventListener("scroll", n), r && t.removeEventListener("resize", n);
    }), null == (t = d) || t.disconnect(), d = null, c && cancelAnimationFrame(a);
  };
}
const z = (t, n, o) => {
  const i = new Map(),
    r = {
      platform: O,
      ...o
    },
    l = {
      ...r.platform,
      _c: i
    };
  return e(t, n, {
    ...r,
    platform: l
  });
};
export { P as autoUpdate, z as computePosition, D as getOverflowAncestors, O as platform };